import { t } from "i18next";

import { H2 } from "shared/components/Typography";
import { toolsData } from "shared/data/tools";
import { useRefsContext } from "shared/providers/refs";
import { useToolsContext } from "shared/providers/tools";

import { Tool } from "./components/Tool";
import styles from "./styles";

export function Tools() {
  const { toolsRef } = useRefsContext();
  const { toolRefs, openedTools } = useToolsContext();

  return (
    <styles.ToolsContainer ref={toolsRef}>
      <H2>
        <styles.VioletBlock />
        {t("Tools.Title")}
      </H2>
      {toolsData.map((tool, index) => (
        <Tool
          icon={tool.icon}
          isOpened={openedTools[index.toString()]}
          key={tool.key}
          toolKey={tool.key}
          clientLogos={tool.clientLogos}
          reference={toolRefs[index]}
          index={index}
          additionalButton={tool.additionalButton}
          clientsIsPartners={tool.clientsIsPartners}
        />
      ))}
      <styles.OrangeBlock />
    </styles.ToolsContainer>
  );
}
